import React, { Component } from "react";
import { Helmet } from "react-helmet";

class ContentProjectMandolez extends Component {
  render() {
    return (
      <div className="detail-gallery">
        <Helmet>
          <title>Chemcoats Project | Mandolez</title>
          <meta
            name="description"
            content="The Cadbury manufacturing plant in Sri City faced a critical safety concern related to the risk of electrical shocks near high-voltage electrical panels, which posed a significant danger to passing work crews."
          />
          <meta name="author" content="Your Company Name" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.chemcoats.com/mandolez" />
        </Helmet>
        <div
          className="themesflat-spacer clearfix"
          data-desktop={21}
          data-mobile={21}
          data-smobile={21}
        />
        <div
          className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
          data-gap={0}
          data-column={1}
          data-column2={1}
          data-column3={1}
          data-auto="false"
        >
          <div className="owl-carousel owl-theme">
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Mandolez/access-floors17.png"
                    alt="Mandolez"
                    title="Mandolez"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Mandolez/access-floors18-qfoktyz98qo8j1ihyiv63zzj70rkjc3s07abig0bj8.png"
                    alt="Mandolez"
                    title="Mandolez"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Mandolez/access-floors19.png"
                    alt="Mandolez"
                    title="Mandolez"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Mandolez/access-floors20-qfomkwaargt9ra40iq2214cetmm5tvwvznii3apizo.png"
                    alt="Mandolez"
                    title="Mandolez"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Mandolez/WhatsApp-Image-2023-11-14-at-7.16.10-PM-qfojxeuiic3odat32y7e8prqfj8uwruhv1ru2ea92c.jpeg"
                    alt="Mandolez"
                    title="Mandolez"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Mandolez/WhatsApp-Image-2023-11-14-at-7.16.11-PM-qfokl4byvwk178dcn74n4qe9tee51xyrse4ptn4s4k.jpeg"
                    alt="Mandolez"
                    title="Mandolez"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.themesflat-cousel-box */}
        <div
          className="themesflat-spacer clearfix"
          data-desktop={40}
          data-mobile={40}
          data-smobile={40}
        />
        <div className="flat-content-wrap style-3 clearfix">
          <h5 className="title">PROJECT DESCRIPTION</h5>
          <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
          <p className="margin-top-28">
            {/* The Cadbury manufacturing plant in Sri City faced a critical safety
            concern related to the risk of electrical shocks near high-voltage
            electrical panels, which posed a significant danger to passing work
            crews. To mitigate this hazard and ensure a safe working
            environment, the plant required an effective solution that would
            both prevent electrical shocks and comply with stringent safety
            standards. In response to this urgent need, we acted swiftly to
            implement a solution that would meet the highest safety standards. */}
            The Cadbury production unit plant in Sri City raised the greatest
            safety issue in terms of the risk of electrical shocks around
            high-voltage electrical panels, which represented a serious risk to
            any work crew walking past them. To avoid this risk and ensure a
            safe work environment plant required a workable solution that would
            not only eliminate electroshock sources but also comply with the
            strictest safety standards. Together with this urgent necessity, we
            acted immediately to implement a solution that would adhere to the
            most stringent safety requirements.
          </p>
          <p>
            {/* Within a short timeframe, our team successfully addressed the
            concern by applying dielectric coatings specifically designed to
            provide electrical insulation and prevent contact with live
            electrical components. The coatings we applied were fully compliant
            with IS 15652 standards, ensuring that they met the required
            specifications for electrical safety in industrial environments.
            This dielectric coating not only enhanced safety by preventing
            accidental electrical contact but also contributed to maintaining
            the integrity of the plant's electrical systems. */}
            Over a brief period of time our team successfully resolved the issue
            by the use of dielectric coatings, designed to act as electrical
            insulators and to ensure the isolation from live electrical parts.
            The coatings that we deposited were in accordance with ISO 15652
            standard, in order to meet the required electrical safety
            regulations in an industrial environment. Not only did this
            electrical dielectric coating provide enhanced safety by preventing
            spurious electrical shunting, it also aided in maintaining the
            health/integrity of plant power systems.
          </p>
          <p>
            {/* The quick turnaround and effective solution allowed the plant to
            continue operations without compromising the safety of their
            workers. By addressing this critical safety issue with precision and
            adherence to industry standards, we helped ensure a secure and
            compliant working environment for all personnel at the Cadbury
            manufacturing plant in Sri City. */}
            The short turnaround and successful solution to the problem enabled
            the plant to keep running safely that is to say without endangering
            the workers' health. This critical safety problem we addressed,
            carefully, and in accordance with industry standards, allowed us to
            create a secure and compliant working environment for all plant
            staff at the Cadbury manufacturing plant in Sri City.
          </p>
        </div>
      </div>
    );
  }
}

export default ContentProjectMandolez;
