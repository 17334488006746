import React, { Component } from "react";
import { Helmet } from "react-helmet";

class ContentProjectLuxshare extends Component {
  render() {
    return (
      <div className="detail-gallery">
        <Helmet>
          <title>Chemcoats Project | Luxshare</title>
          <meta
            name="description"
            content=" The manufacturer of Apple Watches recently began construction on their new facility in Chennai, where the need for a server room to facilitate seamless data transfer became a priority. "
          />
          <meta name="author" content="Your Company Name" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.chemcoats.com/luxshare" />
        </Helmet>
        <div
          className="themesflat-spacer clearfix"
          data-desktop={21}
          data-mobile={21}
          data-smobile={21}
        />
        <div
          className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
          data-gap={0}
          data-column={1}
          data-column2={1}
          data-column3={1}
          data-auto="false"
        >
          <div className="owl-carousel owl-theme">
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/access-floors14.png"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.03.02-PM-1-qfof9mnmkyf3z2istfas61zp5i86xrrcsllo6b23qc.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.03.02-PM-2-qfoiwb5uqrb83ep8qeujbktqb04a2vjx3eqv47aoo4.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.06.06-PM-2-qfoj6jml5tbmiju0yu4agzuf61o5w86p62h78o4cx0.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.03.02-PM-3-qfoj0vnlyrkej62b1vy8xxbe9flihwoy20wu3misf8.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>{" "}
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.03.03-PM-2-qfoj36deo6pmwuq5mvpf1cfyjc9tb9tbpebkbz457o.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>{" "}
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.05.35-PM-qfojl0d6c14h6itmi37lucfs9idicjlztnw73yo95w.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>{" "}
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.06.05-PM-1-qfoaun8cmqs69fge67bfxoa02bu80rlr9pa50gsa5g.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>{" "}
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project/Luxshare/WhatsApp-Image-2023-11-14-at-7.06.06-PM-1-qfoj5olwwa55vf32zyplopo7kbx1u7rk1sy6ejecmc.jpeg"
                    alt="Luxshare"
                    title="Luxshare"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.themesflat-cousel-box */}
        <div
          className="themesflat-spacer clearfix"
          data-desktop={40}
          data-mobile={40}
          data-smobile={40}
        />
        <div className="flat-content-wrap style-3 clearfix">
          <h5 className="title">PROJECT DESCRIPTION</h5>
          <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
          <p className="margin-top-28">
            {/* The manufacturer of Apple Watches recently began construction on
            their new facility in Chennai, where the need for a server room to
            facilitate seamless data transfer became a priority. Given the
            urgency of the requirement, we were tasked with delivering the
            solution within a very tight timeframe. Despite facing challenges
            such as critical contours and undulations in the existing floor, our
            team worked efficiently and meticulously, completing the server room
            flooring installation within the promised 24-hour window. */}
            Recently, a server room facility for the maker of Apple Watch (i.e.
            there is not yet a facility for the seamless transfer of data (ie,
            the absence of which is a central problem). Due to a demand, we were
            forced to hand over the solution in a very short time frame.
            Notwithstanding the situation of critical contours and undulations
            of the floor floor, our group has been meticulously and intensely
            effort to make the server room floor installation end within the
            target regulation time of 24hr.
          </p>
          <p>
            {/* By leveraging advanced floor leveling techniques and specialized
            materials, we ensured that the server room met the required
            standards for both electrostatic discharge (ESD) protection and
            operational efficiency. The successful execution of this project not
            only demonstrated our ability to meet demanding deadlines but also
            reinforced our commitment to overcoming complex challenges in order
            to deliver high-quality results. */}
            Using the state of the art, floor leveling technique and special
            materials, we confirmed that the server room facility complied with
            the appropriate electrode protection equipment and operational
            effectiveness standards. Successful completion of that work not only
            asserted our right to to perform effective work in challenging,
            stressful conditions, but also demonstrated to our employers our
            commitment to performing with resilience and under pressure, and to
            deliver the highest possible standard of work.
          </p>
        </div>
      </div>
    );
  }
}

export default ContentProjectLuxshare;
